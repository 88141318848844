import React, { useState, useEffect } from "react";

const PricePointSelect = ({ primary_price_point, price_points, onChange }) => {
  const [availablePricePoints, setAvailablePricePoints] = useState([]);
  const [pricePointId, setPricePointId] = useState(null);

  useEffect(() => {
    const parsedPricePoints = typeof price_points === "string"
      ? JSON.parse(price_points)
      : price_points;
    
    const openPricePoints = parsedPricePoints.filter(
      (point) => point.registration_count < point.capacity
    );

    // Sort the price points based on some criteria, for example, by id
    openPricePoints.sort((a, b) => a.id - b.id);

    // Update the available options
    setAvailablePricePoints(openPricePoints);

    // Set either primary or first available option as the default
    let defaultPricePointId = null;

    if (primary_price_point && openPricePoints.find(pp => pp.id === primary_price_point.id)) {
      defaultPricePointId = primary_price_point.id;
    } else if (openPricePoints.length > 0) {
      defaultPricePointId = openPricePoints[0].id;
    }

    if (defaultPricePointId !== null) {
      setPricePointId(defaultPricePointId);

      // Inform the parent component
      onChange(defaultPricePointId);
    }
  }, [price_points, primary_price_point]);

  const handlePricePointChange = (event) => {
    const newPricePointId = event.target.value;
    setPricePointId(newPricePointId);
    onChange(newPricePointId);
  };

  const setUpOptions = (points) => {
    return points.map(({ id, title, price_cents }) => (
      <option value={id} key={id}>
        {`${title} - $${(price_cents / 100).toFixed(2)}`}
      </option>
    ));
  };

  return (
    <select
      className="price_point full-field"
      id="price_point_select"
      name="price_point_id"
      value={pricePointId}
      onChange={handlePricePointChange}
    >
      {setUpOptions(availablePricePoints)}
    </select>
  );
};

export default PricePointSelect;
