import React, { useState, useEffect } from "react";

const TextField = ({
  label,
  fieldName,
  required,
  type,
  autoComplete,
  onChange,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  // Validation function
  const validateInput = (value) => {
    if (required && value.trim() === "") {
      return "This field is required";
    }

    if (type === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      return "Please enter a valid email address";
    }

    // Add more validation rules based on field type or name
    // ...

    return "";
  };

  // Run validation on value change
  useEffect(() => {
    setError(validateInput(value));
  }, [value]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div className="text-field-container">
      {error && <span className="error-span">{error}</span>}
      {label && (
        <label className="text-field full-field" htmlFor={fieldName}>
          {label}
        </label>
      )}
      <input
        id={fieldName}
        name={fieldName}
        type={type || "text"}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default TextField;
