// src/components/EmailInput.js
import React, { useState } from "react";

const EmailInput = ({ name, label, value, onChange }) => {
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const email = e.target.value;
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

    setError(isValid ? null : "Please enter a valid email address");
    onChange({ target: { name, value: email } });
  };

  return (
    <div>
      <label>{label}</label>
      <input type="email" value={value} onChange={handleChange} />
      {error && <p>{error}</p>}
    </div>
  );
};

export default EmailInput;
