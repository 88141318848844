import React from "react";

const countryCodeArray = [
              {
                name: "United States",
                dial_code: "+1",
                code: "US"
              },
              {
                name: "Brazil",
                dial_code: "+55",
                code: "BR"
              },
              {
                name: "Canada",
                dial_code: "+1",
                code: "CA"
              },
              {
                name: "Costa Rica",
                dial_code: "+506",
                code: "CR"
              },
              {
                name: "United Kingdom",
                dial_code: "+44",
                code: "GB"
              },
              {
                name: "Argentina",
                dial_code: "+54",
                code: "AR"
              },
              {
                name: "Australia",
                dial_code: "+61",
                code: "AU"
              },
              {
                name: "Peru",
                dial_code: "+51",
                code: "PE"
              },
              {
                name: "Austria",
                dial_code: "+43",
                code: "AT"
              },
              {
                name: "Bahamas",
                dial_code: "+1242",
                code: "BS"
              },
              {
                name: "Belgium",
                dial_code: "+32",
                code: "BE"
              },
              {
                name: "Belize",
                dial_code: "+501",
                code: "BZ"
              },
              {
                name: "Chile",
                dial_code: "+56",
                code: "CL"
              },
              {
                name: "China",
                dial_code: "+86",
                code: "CN"
              },
              {
                name: "Colombia",
                dial_code: "+57",
                code: "CO"
              },
              {
                name: "Dominican Republic",
                dial_code: "+1849",
                code: "DO"
              },
              {
                name: "Ecuador",
                dial_code: "+593",
                code: "EC"
              },
              {
                name: "Germany",
                dial_code: "+49",
                code: "DE"
              },
              {
                name: "Finland",
                dial_code: "+358",
                code: "FI"
              },
              {
                name: "France",
                dial_code: "+33",
                code: "FR"
              },
              {
                name: "Israel",
                dial_code: "+972",
                code: "IL"
              },
              {
                name: "Italy",
                dial_code: "+39",
                code: "IT"
              },
              {
                name: "Jamaica",
                dial_code: "+1876",
                code: "JM"
              },
              {
                name: "Japan",
                dial_code: "+81",
                code: "JP"
              },
              {
                name: "Norway",
                dial_code: "+47",
                code: "NO"
              },
              {
                name: "Paraguay",
                dial_code: "+595",
                code: "PY"
              },
              {
                name: "Poland",
                dial_code: "+48",
                code: "PL"
              },
              {
                name: "Portugal",
                dial_code: "+351",
                code: "PT"
              },
              {
                name: "Spain",
                dial_code: "+34",
                code: "ES"
              },
              {
                name: "Sweden",
                dial_code: "+46",
                code: "SE"
              },
              {
                name: "Switzerland",
                dial_code: "+41",
                code: "CH"
              }
            ];


const CountryCodeDropdown = ({ countryCode, onChange }) => {
  
  const setUpCountryCodeOptions = () => {
    const options = [];
    for (let country of countryCodeArray){
      options.push(
        <option
          value={country.dial_code}
          key={`${country.code} ${country.dial_code}`}
        >
          {`${country.code} ${country.dial_code}`}
        </option>
      );
    }
    return options;
  };

  const onCodeChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="country-code-container quarter">
      <select
        className="country-code-select field"
        value={countryCode}
        onChange={onCodeChange}
      >
        {setUpCountryCodeOptions()}
      </select>
    </div>
  );
};

export default CountryCodeDropdown;
