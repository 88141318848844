// src/components/HeightInput.js
import React, { useState, useEffect } from "react";

const HeightInput = ({ name, label, value, onChange }) => {
  const [feet, setFeet] = useState(0);
  const [inches, setInches] = useState(0);

  useEffect(() => {
    const heightString = `${feet} ft ${inches} inches`;
    onChange({ target: { name, value: heightString } });
  }, [feet, inches]);

  const generateFeetOptions = () => {
    const options = [];
    for (let i = 1; i <= 8; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const generateInchesOptions = () => {
    const options = [];
    for (let i = 0; i <= 11; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  return (
    <div>
      <label>{label}</label>
      <select
        name={`${name}_feet`}
        value={feet}
        onChange={(e) => setFeet(e.target.value)}
      >
        {generateFeetOptions()}
      </select>
      <select
        name={`${name}_inches`}
        value={inches}
        onChange={(e) => setInches(e.target.value)}
      >
        {generateInchesOptions()}
      </select>
    </div>
  );
};

export default HeightInput;
