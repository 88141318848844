import React, { useState } from "react";
import Cleave from "cleave.js/react";
import BirthDateDropdown from "./birth_date_dropdown.js";
import CountryCodeDropdown from "./country_code_dropdown.js";
import StateDropdown from "./state_dropdown.js";
import GenderField from "./gender_field.js";

const ExciteInfoForm = (props) => {
  const initialPricePoints = JSON.parse(props.price_points);

    const [state, setState] = useState({
      showModal: null,
      first_name: "",
      last_name: "",
      birth_date: new Date("2004", "01", "01"),
      age: "",
      gender: "Prefer Not to Say",
      room_number: "",
      emergency_contact: "",
      emergency_contact_country_code: "+1",
      emergency_contact_number: "",
      guardian_contact: "",
      guardian_contact_country_code: "+1",
      guardian_contact_number: "",
      guardian_contact_email: "",
      guardian_contact_relationship: "",
      email: "",
      address: "",
      city: "",
      state: "AL - Alabama",
      zip: "",
      price_points: initialPricePoints,
    });

  const isFullPricePoint = (pricePoint) => pricePoint.registration_count >= pricePoint.capacity;
  const isOpenPricePoint = (pricePoint) => pricePoint.registration_count <= pricePoint.capacity;
  
  const setFullPricePointFlag = () => state.price_points.some(isFullPricePoint);
  const createOpenPricePointArray = () => state.price_points.filter(isOpenPricePoint);
  const createFullPricePointArray = () => state.price_points.filter(isFullPricePoint);

  const updateState = (key, value) => {
      setState(prevState => ({...prevState, [key]: value }));
    };


  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    const errorMessage = item.dataset.errorMessage.replace(
      "the specified number of",
      item.dataset.minLength.toString()
    );
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      $(errorSpan).html("");
    }
  };

  const checkInput = (e) => {
    const { className: itemClass, value: val } = e.target;
    const item = e.target;
    const errorSpan = item.parentElement.querySelector(".error-span");
    showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const validateEmail = (email) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const validateLength = (field, requiredLength) =>
    field.length > requiredLength;

  const valid = () => {
    const {
      first_name,
      last_name,
      email,
      emergency_contact,
      address,
      city,
      zip,
    } = state;
    return (
      validateLength(first_name, 1) &&
      validateLength(last_name, 1) &&
      validateEmail(email) &&
      validateLength(emergency_contact, 4) &&
      validateLength(address, 4) &&
      city &&
      validateLength(zip, 2)
    );
  };

  const stateDropdownChanged = (newState) =>
    updateState("state", newState);

  const genderFieldChanged = (selectedGender) =>
    updateState("gender", selectedGender);

  const countryCodeDropdownChanged = (newCode) =>
    updateState("emergency_contact_country_code", newCode);

  const guardianCountryCodeDropdownChanged = (newCode) =>
    updateState("guardian_contact_country_code", newCode);

  const dateChanged = (newDate) => updateState("birth_date", newDate);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    const countryCode =
      name === "guardian_contact_number"
        ? state.guardian_contact_country_code
        : state.emergency_contact_country_code;
    updateState(name, `${countryCode} ${value}`);
  };

  const setGuardianFields = () => {
    if (props.collect_guardian_info) {
      return (
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <label className={"big-span"}>
            {"Guardian Contact Information: *"}
          </label>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Name"}
            data-error-message={
              "Guardian Contact Name must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact"}
            minLength={5}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Relationship - Mother, Father, etc"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_relationship"}
            minLength={2}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <div className={"half"}>
            <CountryCodeDropdown
              value={state.guardian_contact_country_code}
              onChange={guardianCountryCodeDropdownChanged}
            />
            <Cleave
              type={"tel"}
              id={"phone-mask"}
              className={"ecn phone-us field two-thirds-field"}
              id={"phone-mask"}
              name={"guardian_contact_number"}
              placeholder={"Guardian Contact #"}
              data-error-message={
                "Guardian Phone must be 10 characters. If you are still having issues, clear the field and try again."
              }
              data-min-length={"10"}
              options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
              onChange={handlePhoneChange}
              onBlur={checkInput}
            />
          </div>
          <input
            type={"email"}
            className={"half full-field"}
            placeholder={"Guardian Email"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_email"}
            minLength={5}
            required={true}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      );
    }
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    let data = {
      first_name: state.first_name,
      last_name: state.last_name,
      birth_date: state.birth_date,
      emergency_contact: state.emergency_contact,
      emergency_contact_number: state.emergency_contact_number,
      gender: state.gender,
      guardian_contact: state.guardian_contact,
      guardian_contact_number: state.guardian_contact_number,
      guardian_contact_email: state.guardian_contact_email,
      guardian_contact_relationship: state.guardian_contact_relationship,
      email: state.email,
      player_phone_number: state.player_phone_number,
      room_number: state.room_number,
      address: state.address,
      city: state.city,
      state: state.state,
      zip: state.zip,
    };
    props.saveValues(data);
    return props.nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <div className={"place"}>
        <h1 className={"form-heading"}>
          {"Excite Soccer Programs Participant Information"}
        </h1>
      </div>
      <div className={"camper-info-row"}>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"First Name *"}
          data-error-message={"First Name must be at least two characters"}
          data-min-length={"2"}
          name={"first_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Last Name *"}
          data-error-message={"Last Name must be at least two characters"}
          data-min-length={"2"}
          name={"last_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>
        <label htmlFor={"email"}>{"Family Email: *"}</label>
        <span className={"error-span"}></span>
        <input
          type={"email"}
          className={"camper-email full-field"}
          placeholder={"Family Email *"}
          data-error-message={
            "Email must be valid and longer than the specified number of characters"
          }
          data-min-length={"5"}
          name={"email"}
          minLength={5}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"grade full-field"}
          placeholder={"Room Number/Teacher"}
          data-error-message={
            "The Room Number/Teacher field needs be filled out"
          }
          data-min-length={"1"}
          name={"room_number"}
          minLength={1}
          required={false}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>
        <GenderField
          onChange={genderFieldChanged}
          gender={state.gender}
        />
      </div>
      <div className={"camper-info-row"}>
        <BirthDateDropdown
          onChange={dateChanged}
          birth_date={state.birth_date}
        />
      </div>
      <div className={"camper-info-row"}>
        <label className={"big-span"}>
          {"Emergency Contact Information: *"}
        </label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Emergency Contact Full Name"}
          data-error-message={
            "Emergency Contact Name and Number must be longer than the specified number of characters."
          }
          data-min-length={"5"}
          name={"emergency_contact"}
          minLength={4}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="half">
        <CountryCodeDropdown
          value={state.emergency_contact_country_code}
          onChange={countryCodeDropdownChanged}
        />
        <Cleave
          id="phone-mask"
          className={"ecn phone-us field two-thirds-field"}
          type={"tel"}
          name={"emergency_contact_number"}
          placeholder={"Emergency Contact #"}
          data-error-message={
            "EC Phone must be 10 characters. If you are still having issues, clear the field and try again."
          }
          data-min-length={"10"}
          options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
          onChange={handlePhoneChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>{setGuardianFields()}</div>
      <div className={"camper-info-row address"}>
        <label>{"Primary Address: *"}</label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"address full-field"}
          placeholder={"Street Address"}
          data-error-message={"Please enter a valid address"}
          data-min-length={"5"}
          name={"address"}
          minLength={5}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row address"}>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"city full-field"}
          placeholder={"City"}
          data-error-message={
            "City must be longer than the specified number of characters."
          }
          data-min-length={"4"}
          name={"city"}
          minLength={4}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="camper-info-row">
        <div className="error-span"></div>
        <StateDropdown
          value={state.state}
          onChange={stateDropdownChanged}
        />
        <input
          type={"text"}
          className={"zip field third-field"}
          placeholder={"Zip Code (i.e. 20008)"}
          data-error-message={"Please enter a valid zip code."}
          data-min-length={"2"}
          name={"zip"}
          minLength={2}
          maxLength={10}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <button
        type={"submit"}
        className={"button button-primary create"}
        disabled={!valid()}
        style={props.buttonStyles}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
};
export default ExciteInfoForm;
