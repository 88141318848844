import React from "react";

const GradeInput = ({ name, label, value, onChange }) => {
  const grades = [
    "Pre-K",
    "Kindergarten",
    "1st Grade",
    "2nd Grade",
    "3rd Grade",
    "4th Grade",
    "5th Grade",
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
    "College Freshman",
    "College Sophomore",
    "College Junior",
    "College Senior",
  ];

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        <option value="">Select a grade</option>
        {grades.map((grade) => (
          <option key={grade} value={grade}>
            {grade}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GradeInput;
